import { graphql, useStaticQuery } from 'gatsby';
import React from 'react';
import BlogPostCard from '../BlogPostCard';
import { Link } from 'gatsby';
import { Col, Row } from 'react-bootstrap';

const LatestBlogPosts = () => {

    const query = useStaticQuery(graphql`
        query {
            allWpPost(limit: 2, sort: {order: DESC, fields: date}) {
                edges {
                  node {
                    id
                    title
                    date
                    slug
                    featuredImage {
                      node {
                        localFile {
                          childImageSharp {
                            gatsbyImageData
                          }
                        }
                      }
                    }
                  }
                }
              }
        }
    `);


    return (<div style={{margin: '0 auto',  paddingTop: 25, display: 'flex', justifyContent: 'center'}}>
      <Row>
        { query.allWpPost.edges.map((edge: any, i: number) => <Col key={i} xs={12} sm={12} md={6}><BlogPostCard title={edge.node.title} slug={edge.node.slug} image={edge.node.featuredImage.node.localFile.childImageSharp.gatsbyImageData} date={edge.node.date}/></Col>)}
        </Row>
    </div>)

}

export default LatestBlogPosts;