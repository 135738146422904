import React from "react";
import Layout from "../../Layout";
import IntroBlock from "../../components/IntroBlock";
import LatestBlogPosts from "../../components/LatestBlogPosts";
import Hero from "../../components/Hero";
import GreyBox from "../../components/GreyBox";
import { Container, Row, Col, Breadcrumb } from "react-bootstrap";
import { StaticImage } from "gatsby-plugin-image";
import SEO from "../../components/SEO";
import CTABox from "../../components/CTABox";
import { Link } from 'gatsby';

const CCAS = () => {
  return (
    <Layout>
      <SEO title="Climate Change & Sustainability" />

      <Hero imageName="ccasBackground">
        <div style={{ width: "50%" }} className="d-none d-sm-block">
          <h1 className="whiteText servicesHeading">
            How will CC&S regulation <br /> impact your business?
          </h1>
          <p className="whiteText">
            There are clear indications from international climate change
            negotiations that a number of national, regional and sectorial
            carbon markets will dramatically increase over the next decade, as
            regulators turn to the market as the most effective mechanism to
            control GHGs.
          </p>
        </div>
      </Hero>

      <IntroBlock>
        <Breadcrumb>
          <Breadcrumb.Item as={Link} to="/services">Services</Breadcrumb.Item>
          <Breadcrumb.Item>
            Climate Change & Sustainability
          </Breadcrumb.Item>
        </Breadcrumb>
        <h1>Climate Change & Sustainability</h1>
        <p>
          Carbon pricing is increasing the cost of doing business, both directly
          on your emissions and through higher energy prices. Many schemes
          provide fines and penalties for non – compliance with potentially
          serious financial implications. When competing in a global market
          place, the response to carbon regulation is fast becoming a source of
          competitive advantage.
        </p>
      </IntroBlock>

      <section>
        <div className="text-center">
          <div style={{ width: "40%", margin: "0 auto" }}>
            {/* <h3>
              What are leading organisations doing to reduce costs and prepare
              for regulation?
            </h3> */}
          </div>
        </div>

        <Container fluid>
          <Row>
            <Col xs={12} sm={6}>
              <h3>
                What are leading organisations doing to reduce costs and prepare
                for regulation?
              </h3>
              <span className="smallDividerLeft"></span>
              <p>
                Leading companies are learning how regulation can impact them
                and preparing CC&S implementing strategies. Early adopters
                understand the importance of collecting GHG data to set internal
                baselines to stretching reduction strategies over a period of
                time.{" "}
              </p>
              <p>
                Securing buy-in from senior management aligned to a carefully
                planned and executed process of internal engagement is key to
                short term cost savings.
              </p>
            </Col>
            <Col
              xs={12}
              sm={6}
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <StaticImage
                src="../../images/pages/services/ccas/chart-a@3x.png"
                height={300}
                style={{ margin: "auto auto" }}
                alt=""
              />
            </Col>
          </Row>
        </Container>
      </section>

      <section>
        <div className="text-center">
          <h2>The TBL Toolkit</h2>
          <p>
            <b>How can TBL assist you in your organisational strategy?</b>
          </p>

          <Container fluid>
            <Row>
              <Col xs={12} sm={6}>
                <GreyBox>
                  <h4>Harmonising Values</h4>
                  <p>
                    We have a clear track record of aligning CC&S activities
                    into the core business strategy, not working in silos but
                    harmonising strategy, business case and implementation
                    within the organisation.
                  </p>
                </GreyBox>
              </Col>
              <Col xs={12} sm={6}>
                <GreyBox>
                  <h4>OPERATIONAL INSIGHT</h4>
                  <p>
                    We understand the commercial importance: we combine GHG
                    policy, implementation and operational insight with scenario
                    planning, project, quality and lean management to address
                    your pressing needs.
                  </p>
                </GreyBox>
              </Col>
            </Row>
            <Row>
              <Col xs={12} sm={6}>
                <GreyBox>
                  <h4>PEERLESS EXPERIENCE</h4>
                  <p>
                    We have a track record of working international with
                    governments and leading organisations around the world and
                    have exceptional hands on experience in the strategy,
                    implementation and operation of Market Based Mechanisms
                    (MBM).
                  </p>
                </GreyBox>
              </Col>
              <Col xs={12} sm={6}>
                <GreyBox>
                  <h4>STRATEGY ALIGNMENT</h4>
                  <p>
                    We help our clients to align green growth strategy with
                    regulatory and corporate CC&S targets
                  </p>
                </GreyBox>
              </Col>
            </Row>
          </Container>
        </div>
      </section>

      <section>
        <div className="text-center">
          <h2>Latest Research</h2>
          <LatestBlogPosts />
        </div>
      </section>

      <Container>
        <Row>
          <Col
            style={{
              display: "flex",
              alignItems: "center",
              flexDirection: "column",
            }}
          >
            <CTABox />
          </Col>
        </Row>
      </Container>
    </Layout>
  );
};

export default CCAS;
